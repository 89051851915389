import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function initMasonLines() {
  // Selecting all wrappers
  const masonWraps = document.querySelectorAll("[data-mason-w]");

  masonWraps.forEach((masonWrap) => {
    // Selecting lines within each wrapper
    const masonX = masonWrap.querySelectorAll(
      ".mason__line.is--top, .mason__line.is--btm"
    );
    const masonY = masonWrap.querySelectorAll(
      ".mason__line.is--left, .mason__line.is--right"
    );

    let duration = 1;

    // checks
    if (masonX.length === 0 && masonY.length === 0) {
      return;
    }
    if (masonX.length > 0) {
      gsap.set(masonX, { scaleX: 0 });
    }
    if (masonY.length > 0) {
      gsap.set(masonY, { scaleY: 0 });
    }

    // ScrollTrigger for the masonWrap
    ScrollTrigger.create({
      trigger: masonWrap,
      start: "top 80%",
      onEnter: () => {
        if (masonX.length > 0) {
          gsap.to(masonX, {
            scaleX: 1,
            duration: duration,
            ease: "power2.inOut",
            stagger: 0.1,
          });
        }
        if (masonY.length > 0) {
          gsap.to(masonY, {
            scaleY: 1,
            duration: duration,
            ease: "power2.inOut",
            stagger: 0.1,
          });
        }
      },
    });
  });
}

function initFlashIn() {
  // Selecting all elements with [data-flash]
  const wraps = document.querySelectorAll("[data-flash]");

  let duration = 0.1;

  // Check if there are any wraps
  if (wraps.length === 0) {
    return;
  }

  wraps.forEach((wrap) => {
    const transformProperty = Math.random() < 0.5 ? "x" : "y";
    const transformValue = Math.random() < 0.5 ? "-4rem" : "4rem";

    // Set initial GSAP properties
    let initialProperties = { autoAlpha: 0 };
    initialProperties[transformProperty] = transformValue;
    gsap.set(wrap, initialProperties);

    let repeat = Math.floor(Math.random() * 3) + 2;

    ScrollTrigger.create({
      trigger: wrap,
      start: "top 80%",
      onEnter: () => {
        gsap.to(wrap, {
          autoAlpha: 1,
          duration: duration,
          repeat: repeat,
          ease: "none",
          onComplete: () => {
            // Reset the transform properties
            gsap.set(wrap, { x: "0rem", y: "0rem" });
          },
        });
      },
    });
  });
}

function initFlashVisSlides() {
  // Selecting all elements with [data-flash]
  const wraps = document.querySelectorAll(
    "[data-vision-slide], [data-blog-slide]"
  );

  let duration = 0.1;

  // Check if there are any wraps
  if (wraps.length === 0) {
    return;
  }

  wraps.forEach((wrap) => {
    const transformProperty = Math.random() < 0.5 ? "x" : "y";
    const transformValue = Math.random() < 0.5 ? "-4rem" : "4rem";

    // Set initial GSAP properties
    let initialProperties = { autoAlpha: 0 };
    initialProperties[transformProperty] = transformValue;
    gsap.set(wrap, initialProperties);

    let repeat = Math.floor(Math.random() * 3) + 2;

    ScrollTrigger.create({
      trigger: wrap,
      start: "top 80%",
      onEnter: () => {
        gsap.to(wrap, {
          autoAlpha: 1,
          duration: duration,
          repeat: repeat,
          ease: "none",
          onComplete: () => {
            // Reset the transform properties
            gsap.set(wrap, { x: "0rem", y: "0rem" });
          },
        });
      },
    });
  });
}

function initFlashInstituteScroll() {
  const tabs = document.querySelectorAll("[data-ip-parent]");

  // Check if there are any tabs
  if (!tabs.length) {
    return;
  }

  let duration = 0.1;
  const delays = [0.5, 0.8, 1];

  tabs.forEach((tab) => {
    const elems = tab.querySelectorAll(".ip__logo--item");
    gsap.set(elems, { autoAlpha: 0, y: "4rem" });

    ScrollTrigger.create({
      trigger: tab,
      start: "top 80%",
      onEnter: () => {
        elems.forEach((elem) => {
          const overlay = elem.querySelector(".ip__logo--ol");
          let repeat = Math.floor(Math.random() * 3) + 2;
          let delay = delays[Math.floor(Math.random() * delays.length)];

          gsap.to(elem, {
            delay: delay,
            autoAlpha: 1,
            duration: duration,
            repeat: repeat,
            ease: "none",
            onComplete: () => {
              elem.style.removeProperty("transform");
            },
          });
          gsap.to(overlay, {
            delay: delay + 0.1,
            autoAlpha: 0,
            duration: duration,
            repeat: repeat,
            ease: "none",
            onComplete: () => {
              elem.style.removeProperty("transform");
            },
          });
        });
      },
    });
  });
}

function initFlashInstituteHover() {
  const partnerWrap = document.querySelector("[data-ip-section]");
  const items = partnerWrap.querySelectorAll(".ip__logo--item");
  items.forEach((item) => {
    item.addEventListener("mouseenter", function () {
      const overlay = this.querySelector(".ip__logo--ol");
      if (overlay) {
        gsap.to(overlay, {
          autoAlpha: 1,
          duration: 0.1,
          repeat: 1,
          onComplete: function () {
            gsap.set(overlay, { autoAlpha: 0 });
          },
        });
      }
    });

    // On mouseleave, ensure the overlay is not visible.
    item.addEventListener("mouseleave", function () {
      const overlay = this.querySelector(".ip__logo--ol");
      if (overlay) {
        gsap.set(overlay, { autoAlpha: 0 });
      }
    });
  });
}

function initFlashParents() {
  const tabs = document.querySelectorAll("[data-flash-parent]");

  // Check if there are any tabs
  if (!tabs.length) {
    return;
  }

  let duration = 0.1;
  const delays = [0.5, 0.8, 1];

  tabs.forEach((tab) => {
    const elems = tab.querySelectorAll("[data-flash-child]");
    gsap.set(elems, { autoAlpha: 0, y: "4rem" });

    ScrollTrigger.create({
      trigger: tab,
      start: "top 80%",
      onEnter: () => {
        elems.forEach((elem) => {
          let repeat = Math.floor(Math.random() * 3) + 2;
          let delay = delays[Math.floor(Math.random() * delays.length)];

          gsap.to(elem, {
            delay: delay,
            autoAlpha: 1,
            duration: duration,
            repeat: repeat,
            ease: "none",
            onComplete: () => {
              elem.style.removeProperty("transform");
            },
          });
        });
      },
    });
  });
}

// function initFlashText() {
//   const containers = document.querySelectorAll("[data-text-flash]");
//   if (!containers.length) return;

//   gsap.utils.toArray(containers).forEach((container) => {
//     const words = gsap.utils.toArray(container.querySelectorAll(".word"));
//     if (!words.length) return;

//     words.forEach((word) => {
//       const transformProperty = Math.random() < 0.5 ? "x" : "y";
//       const transformValue = Math.random() < 0.5 ? "-4rem" : "4rem";
//       let repeat = Math.floor(Math.random() * 3) + 2;

//       // Create ScrollTrigger for each word
//       ScrollTrigger.create({
//         trigger: word,
//         start: "top 90%",
//         onEnter: () => {
//           gsap.to(word, {
//             autoAlpha: 1,
//             duration: 0.1,
//             repeat: repeat,
//             ease: "none",
//             onComplete: () => {
//               word.style.removeProperty("transform");
//             },
//           });
//         },
//       });

//       // Set initial properties for flashing
//       gsap.set(word, { autoAlpha: 0, [transformProperty]: transformValue });
//     });
//   });
// }

function initFlashText() {
  const containers = document.querySelectorAll("[data-text-flash]");
  if (!containers.length) return;

  gsap.utils.toArray(containers).forEach((container) => {
    const words = gsap.utils.toArray(container.querySelectorAll(".word"));
    if (!words.length) return;

    const useParentTrigger =
      container.getAttribute("data-text-flash") === "parent";

    const animateWord = (word) => {
      const transformProperty = Math.random() < 0.5 ? "x" : "y";
      const transformValue = Math.random() < 0.5 ? "-4rem" : "4rem";
      let repeat = Math.floor(Math.random() * 3) + 2;

      // Apply transform just before animation
      gsap.set(word, { autoAlpha: 0, [transformProperty]: transformValue });

      gsap.to(word, {
        autoAlpha: 1,
        duration: 0.1,
        repeat: repeat,
        ease: "none",
        onComplete: () => {
          word.style.removeProperty("transform");
        },
      });
    };

    // Create ScrollTrigger
    ScrollTrigger.create({
      trigger: useParentTrigger ? container : words,
      start: "top 90%",
      onEnter: () => {
        if (useParentTrigger) {
          words.forEach(animateWord);
        } else {
          words.forEach((word) => {
            ScrollTrigger.create({
              trigger: word,
              start: "top 90%",
              onEnter: () => animateWord(word),
            });
          });
        }
      },
    });
  });
}

function initCodeHovers() {
  const parents = document.querySelectorAll('[data-code-hover="parent"]');

  parents.forEach((parent) => {
    const text = parent.querySelector('[data-code-hover="text"]');
    if (text) {
      text.dataset.originalText = text.textContent;
    }
    parent.addEventListener("mouseenter", function () {
      // Use the stored original text for the scramble animation
      gsap.to(text, {
        duration: 1,
        scrambleText: {
          text: text.dataset.originalText,
          chars: text.dataset.originalText,
          revealDelay: 0,
          delimiter: "",
          speed: 0.3,
        },
      });
    });
  });
}

function initCodeInScroll() {
  const codeTxt = document.querySelectorAll("[data-code-in]");
  const scrambleDuration = 1; // Duration of the scramble animation
  const chars = "░▒▓█▀▄?"; // Characters used for scrambling

  codeTxt.forEach((block) => {
    // Store the original text in a data attribute and clear the text content
    block.dataset.originalText = block.textContent;
    block.textContent = "";

    gsap.to(block, {
      scrollTrigger: {
        trigger: block,
        start: "top 80%",
        onEnter: () => {
          // Use the stored original text for the scramble animation
          gsap.to(block, {
            duration: scrambleDuration,
            scrambleText: {
              text: block.dataset.originalText,
              chars: chars,
              revealDelay: 0,
              delimiter: "",
              speed: 0.3,
            },
          });
        },
      },
    });
  });
}

export function initAnims() {
  setTimeout(() => initFlashVisSlides(), 500); // initFlashVisSlides();
  initFlashInstituteScroll();
  initFlashInstituteHover();
  initMasonLines();
  initFlashIn();
  initFlashParents();
  initFlashText();
  initCodeInScroll();
  if (!window.isTabletOrBelow) {
    // initCodeHovers();
  }
}
