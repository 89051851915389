import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function initializeFooterSVGAnimations() {
  const footer = document.querySelector("[data-footer]");
  const svgs = footer.querySelectorAll(".footer__large--svg");
  const svgMasks = footer.querySelectorAll(".footer__mask--w");

  // init state
  gsap.set(svgs, {
    y: "100%",
    rotateX: -100,
    rotateY: -100,
  });

  ScrollTrigger.create({
    trigger: footer,
    start: "top center",
    onEnter: () =>
      gsap.to(svgs, {
        y: "0%",
        rotateX: 0,
        rotateY: 0,
        stagger: 0.1,
        duration: 1.5,
        ease: "power3.out",
        onComplete: () => {
          footer.addEventListener("mousemove", moveSVGsOnMouseMove);
          footer.addEventListener("mouseleave", resetSVGsPosition);
        },
      }),
    onLeaveBack: () =>
      gsap.to(svgs, {
        y: "100%",
        rotateX: -100,
        rotateY: -100,
        stagger: 0.1,
        duration: 1.5,
        ease: "power3.out",
      }),
    onEnterBack: () =>
      gsap.to(svgs, {
        y: "0%",
        rotateX: 0,
        rotateY: 0,
        stagger: 0.1,
        duration: 1.5,
        ease: "power3.out",
      }),
  });

  // Event listeners for mouse down and up to move masks apart and back
  // footer.addEventListener("mousedown", () => moveMasksApart());
  // footer.addEventListener("mouseup", () => moveMasksTogether());

  // Function to move masks apart
  function moveMasksApart() {
    gsap.to(svgMasks, {
      x: (i, target) => {
        // Calculate stagger order to make the animation emanate from the outside in
        const staggerOrder = Math.floor(Math.abs(i - svgMasks.length / 2));
        return `${1 * (i < svgMasks.length / 2 ? -1 : 1)}rem`; // Move left for the first half, right for the second half
      },
      stagger: {
        each: 0.05,
        from: "edges", // This ensures that the animation starts from the outside masks
      },
      ease: "power3.out",
    });
  }

  // Function to move masks back together
  function moveMasksTogether() {
    gsap.to(svgMasks, {
      x: 0,
      stagger: 0.1,
      ease: "power3.in",
      overwrite: true,
    });
  }

  // Function to move and rotate SVGs based on mouse position
  function moveSVGsOnMouseMove(event) {
    const footerRect = footer.getBoundingClientRect();
    const xPercent = (event.clientX - footerRect.left) / footerRect.width;
    const yPercent = (event.clientY - footerRect.top) / footerRect.height;
    const maxMovement = 5;
    const xMovement = maxMovement * (xPercent - 0.5) * 2;
    const yMovement = maxMovement * (yPercent - 0.5) * 2;

    // Calculate rotation and scale values based on mouse position
    const rotateX = yPercent * 20 - 10;
    const rotateY = xPercent * 20 - 10;
    const scale =
      0.9 +
      0.1 *
        (1 - Math.max(Math.abs(xPercent - 0.5), Math.abs(yPercent - 0.5)) * 2);

    gsap.to(svgs, {
      x: `${xMovement}rem`,
      y: `${yMovement}rem`,
      rotateX: rotateX,
      rotateY: rotateY,
      scale: scale,
      stagger: 0.1,
      duration: 0.5,
      ease: "power1.out",
    });
  }

  function resetSVGsPosition() {
    gsap.to(svgs, {
      x: 0,
      y: 0,
      rotateX: 0,
      rotateY: 0,
      scale: 1,
      stagger: 0.01,
      duration: 0.5,
      ease: "power1.in",
      overwrite: true,
    });
  }
}

// ------------------------------ footer
function footerHeight() {
  const footerParent = document.querySelector('[data-footer="content"]');
  const footerChild = document.querySelector('[data-footer="block"]');

  if (footerParent && footerChild) {
    const parentHeight = footerParent.offsetHeight;
    footerChild.style.height = `${parentHeight}px`;
  }

  if (!window.isTabletOrBelow) {
    window.addEventListener("resize", footerHeight);
  }
}

export function initFooter() {
  //   footerHeight();
  initializeFooterSVGAnimations();
}
