import SplitType from "split-type";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function runUtils() {
  console.log("utils");
}

// ------ split function
export function runSplit(selector, type) {
  return new SplitType(selector, {
    types: type,
  });
}

// ----- handle resize
let splitInstances = [];

export function onResize() {
  // Revert all SplitType instances
  splitInstances.forEach((instance) => {
    instance.revert();
  });

  // clear the array after reverting
  splitInstances = [];

  // run globalSplit again
  globalSplit();
}
function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

// debounce resize
export const debouncedResize = debounce(onResize, 250); // 250 ms debounce period

// ----- split all text elements
export function globalSplit() {
  runSplit("[split-text]", "lines, words");
}

// ----- split all text code elements
export function codeSplit() {
  runSplit("[split-code]", "lines, words");
}

// ----- reinit webflow
export function reinitWebflow() {
  const w = window.Webflow;
  w.destroy();
  w.ready();
}

// ---- reload on resize between tablet / desktop
export function reloadOnResize() {
  let previousWidth = window.innerWidth;

  window.onresize = function () {
    const currentWidth = window.innerWidth;
    if (
      (previousWidth < 991 && currentWidth >= 991) ||
      (previousWidth >= 991 && currentWidth < 991)
    ) {
      location.reload();
    }
    previousWidth = currentWidth;
  };
}

// ----- refresh refreshScrollTriggers
export function refreshScrollTriggers() {
  // window.ScrollTrigger.refresh();
  ScrollTrigger.refresh();
}

// ----- play videos
export function initVideoLoad() {
  // Function to load and play videos
  function loadAndPlayVideos() {
    const loadVideoElements = document.querySelectorAll("[data-video-load]");

    loadVideoElements.forEach((videoElement) => {
      const video = videoElement.querySelector("video");
      const videoUrl = video.dataset.videoUrl; // Get the video URL from the data attribute

      if (videoUrl) {
        const sourceElement = video.querySelector("source");
        sourceElement.src = videoUrl; // Set the source element's src attribute
        video.load(); // Load the video with the new source

        // Attempt to play the video
        video.play().catch((e) => {
          console.error("Auto-play was prevented: ", e);
        });
      }
    });
  }

  loadAndPlayVideos();
}

// ----- welcome message
export function runWelcome() {
  let msg = "%c ☩ Site built by, https://itsoffbrand.com ☩";
  let styles = [
    "font-size: 12px",
    "color: #fffce1",
    "font-family: monospace",
    "background: #0e100f",
    "display: inline-block",
    "padding: 1rem 3rem",
    "border: 1px solid #fffce1",
    "border-radius: 4px;",
  ].join(";");
  console.log(msg, styles);
}
