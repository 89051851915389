import gsap from "gsap";
import {
  runWelcome,
  debouncedResize,
  reloadOnResize,
  globalSplit,
  codeSplit,
} from "./js/utils";
import { initHome } from "./js/page/home";
import { initCursor } from "./js/cursor";
import { initFooter } from "./js/footer";

// handle preferes reduced motion
// if (!window.prm) {}
window.prm = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

// handle tablet cut off
// if (!window.isTabletOrBelow) {}
const tabletMediaQuery = window.matchMedia("(max-width: 991px)");
window.isTabletOrBelow = tabletMediaQuery.matches;
tabletMediaQuery.addEventListener("change", (event) => {
  window.isTabletOrBelow = event.matches;
});

// handle mobile cut off
// if (!window.isMobile) {}
const mobileMediaQuery = window.matchMedia("(max-width: 479px)");
window.isMobile = tabletMediaQuery.matches;
mobileMediaQuery.addEventListener("change", (event) => {
  window.isMobile = event.matches;
});

async function onStart() {
  const initPage = document.querySelector("[data-page]").dataset.page;
  const initStart = document.querySelector('[data-start="hidden"]');
  await document.fonts.ready;

  globalSplit();
  codeSplit();

  if (initPage === "home") {
    initHome();
  }

  gsap.to(initStart, {
    autoAlpha: 1,
    duration: 0.3,
  });
}

export function app() {
  runWelcome();
  onStart();
  reloadOnResize();
  initCursor();

  if (!window.isTabletOrBelow) {
    window.addEventListener("resize", debouncedResize);
    initFooter();
  }
}
