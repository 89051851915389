import Lenis from "@studio-freight/lenis";

// ----- webflow editor
// function handleEditor(onEditorView = null) {
//   // console.log(Webflow.env("editor"));
//   if (Webflow.env("editor") !== undefined) {
//     if (onEditorView !== null) onEditorView();
//     console.log("Webflow Editor View");
//     return true;
//   } else {
//     return false;
//   }
// }

// ----- inject css
function injectCSS(string) {
  const style = document.createElement("style");
  style.textContent = string;
  document.head.append(style);
}

/* ---  Additional CSS */
const customcss = injectCSS(`
    .lenis.lenis-smooth {
      scroll-behavior: auto;  
    }
    html.lenis {
      height: auto;
    }
`);

const params = {
  // defaults
  wrapper: window,
  duration: 0.825,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  orientation: "vertical",
  smoothWheel: true,
  syncTouch: true,
  touchMultiplier: 0.1,
  touchInertiaMultiplier: 10,
  infinite: false,
  // internal
  useOverscroll: true,
  useControls: true,
  useAnchor: true,
  useRaf: true,
  autoResize: false,
};

/*  --- Additional CSS */
class Scroll extends Lenis {
  constructor(params) {
    super({
      params,
    });

    // console.log("hi");
    this.params = params;
    this.isActive = true;
    this.init();

    // -- interface
    this.call = {
      stop: () => this.stop(),
      start: () => this.start(),
    };
  }

  init() {
    this.config();
    this.render();

    if (this.params.useRaf) {
      this.y = window.scrollY;
      this.max = window.innerHeight;
      this.speed = 0;
      this.percent = this.y / (document.body.scrollHeight - window.innerHeight);
      this.direction = 0;
      this.on("scroll", (e) => this.outScroll(e));
    }

    // handleEditor(this.destroy.bind(this));
  }

  reconfig() {
    if (this.scrollinks)
      this.scrollinks.forEach((item) => (item.onclick = null));
    this.config();
  }

  config() {
    if (this.params.useAnchor)
      this.scrolllinks = [
        ...document.querySelectorAll("[data-scrolllink]"),
      ].map((item) => {
        const target = document.querySelector(item.dataset.scrolllink);
        if (target) {
          item.onclick = (e) => {
            e.preventDefault();
            this.scrollTo(target);
          };
        }
        return item;
      });

    if (this.params.useOverscroll)
      [...document.querySelectorAll('[data-scroll="overscroll"]')].forEach(
        (item) => item.setAttribute("onwheel", "event.stopPropagation()")
      );

    if (this.params.useControls) {
      [...document.querySelectorAll('[data-scroll="stop"]')].forEach((item) => {
        item.onclick = () => {
          this.stop();
          this.isActive = false;
        };
      });

      [...document.querySelectorAll('[data-scroll="start"]')].forEach(
        (item) => {
          item.onclick = () => {
            this.start();
            this.isActive = true;
          };
        }
      );

      [...document.querySelectorAll('[data-scroll="toggle"]')].forEach(
        (item) => {
          item.onclick = () => {
            if (this.isActive) {
              this.stop();
              this.isActive = false;
            } else {
              this.start();
              this.isActive = true;
            }
          };
        }
      );
    }
  }

  render(time) {
    this.raf(time);
    window.requestAnimationFrame(this.render.bind(this));
    // if (this.params.useRaf) this.call.onRender(time);
  }

  outScroll({ scroll, limit, velocity, progress, direction }) {
    // console.log(scroll, limit, velocity, progress, direction);
    this.y = scroll || 0;
    this.max = limit || window.innerHeight;
    this.speed = velocity || 0;
    this.percent = progress || 0;
    this.direction = 0;

    if (this.params.useRaf) {
      window.dispatchEvent(
        new CustomEvent("sscroll", {
          detail: {
            y: this.y,
            max: this.max,
            speed: this.speed,
            percent: this.percent,
            direction: this.direction,
          },
        })
      );
    }
  }

  renderWebflow(time) {}
}

window.SScroll = new Scroll(params);

/* ---- Events */

// window.addEventListener("sscroll", (e) => {
//   ScrollTrigger.update();
// });

/* ---- Callbacks */

// window.SScroll.call.stop()
// window.SScroll.call.start()

export function lenisResize() {
  window.SScroll.resize();
}

export function lenisToTop(duration = 0) {
  // default duration is set to immediate
  const options = {};

  if (duration === 0) {
    options.immediate = true;
  } else {
    options.duration = duration;
  }

  window.SScroll.scrollTo(0, options);
}
