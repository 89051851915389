import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { refreshScrollTriggers, initVideoLoad } from "../utils";
import { initAnims } from "../anim";
import { initSwipers } from "../sliders";
import { lenisResize } from "../../lib/lenis";

gsap.registerPlugin(ScrollTrigger, ScrambleTextPlugin);

// ---------------------------------------- Intro section
function initIntro() {
  let randomWordsInterval;
  const hudSlash = document.querySelector("[data-hud-slash]");

  function initHeroSettings() {
    const loadTextElements = document.querySelectorAll("[data-load-text]");
    gsap.set(loadTextElements, { autoAlpha: 0 });
    gsap.set(hudSlash, { autoAlpha: 0 });
  }

  // function to gsap.to the hud slash from 0 to 1 autoAlpha with a repeat of 2 over 0.2s
  function initSlash() {
    gsap.to(hudSlash, {
      duration: 0.1,
      autoAlpha: 1,
      repeat: 2,
    });
  }
  // function to gsap.to the hud slash from 1 to 0 autoAlpha with a repeat of 1 over 0.2s
  function destroySlash() {
    gsap.to(hudSlash, {
      duration: 0.1,
      autoAlpha: 0,
      repeat: 1,
    });
  }

  // --------------- first load
  function initCodeIn() {
    const loadTextElements = document.querySelectorAll("[data-load-text]");
    let elementDelay = 0;

    loadTextElements.forEach((element) => {
      const lines = element.querySelectorAll(".line");

      lines.forEach((line, lineIndex) => {
        const words = line.querySelectorAll(".word");

        let lineDelay = elementDelay + lineIndex * 0.05;

        words.forEach((word, wordIndex) => {
          // Check if originalText is stored in data attribute; if not, store it
          if (!word.dataset.originalText) {
            word.dataset.originalText = word.textContent;
          }

          word.textContent = ""; // Clear text to start empty

          gsap.to(word, {
            duration: 1,
            scrambleText: {
              text: word.dataset.originalText,
              chars: "░▒▓▀▄",
              revealDelay: 0.01,
              delimiter: "",
              speed: 1,
            },
            delay: lineDelay + wordIndex * 0.05,
          });
        });
      });

      elementDelay += -0.5 + lines.length * 0.05;
    });
  }

  function destroyCodeIn() {
    const loadTextElements = document.querySelectorAll("[data-load-text]");
    let elementDelay = 0;

    loadTextElements.forEach((element) => {
      const lines = element.querySelectorAll(".line");

      lines.forEach((line, lineIndex) => {
        const words = line.querySelectorAll(".word");

        let lineDelay = elementDelay + lineIndex * 0.05;

        words.forEach((word, wordIndex) => {
          gsap.to(word, {
            duration: 1,
            scrambleText: {
              text: " ", // Scrambling to an empty space
              chars: "░▒▓█▀▄?",
              revealDelay: 0.01,
              delimiter: "",
              speed: 1,
            },
            delay: lineDelay + wordIndex * 0.05,
          });
        });
      });

      elementDelay += -0.5 + lines.length * 0.05;
    });
  }

  // --------------- random shuffle
  function initRandomWords() {
    clearInterval(randomWordsInterval); // Clear any existing interval to prevent multiple instances

    const words = document.querySelectorAll("[data-load-text] .word");
    const scrambleDuration = 1;
    const chars = "░▒▓█▀▄?";

    randomWordsInterval = setInterval(() => {
      // Assign the interval ID to the variable
      const randomWords = Array.from(words)
        .sort(() => 0.5 - Math.random())
        .slice(0, Math.ceil(words.length * 0.1));

      randomWords.forEach((word) => {
        const originalText = word.dataset.originalText || word.textContent; // Use dataset if available

        gsap.to(word, {
          duration: scrambleDuration,
          scrambleText: {
            text: originalText,
            chars: chars,
            revealDelay: 0,
            delimiter: "",
            speed: 0.3,
          },
        });
      });
    }, 2000);
  }

  function destroyRandomWords() {
    clearInterval(randomWordsInterval); // Clear the interval using its ID
  }

  function initHeroScroll() {
    const triggers = document.querySelectorAll("[data-load-scrolltrigger]");

    if (!window.isMobile) {
      triggers.forEach((trigger) => {
        ScrollTrigger.create({
          trigger: trigger,
          start: "top center",
          end: "bottom 90%",
          onEnter: () => {
            const loadTextElements =
              document.querySelectorAll("[data-load-text]");
            gsap.set(loadTextElements, { autoAlpha: 1 });
            initSlash();
            initCodeIn();
            initRandomWords();
          },
          onLeave: () => {
            destroySlash();
            destroyRandomWords();
            destroyCodeIn();
          },
          onEnterBack: () => {
            initSlash();
            initCodeIn();
            initRandomWords();
          },
          onLeaveBack: () => {
            destroySlash();
            destroyRandomWords();
            destroyCodeIn();
          },
          // markers: true,
        });
      });
    } else {
      triggers.forEach((trigger) => {
        ScrollTrigger.create({
          trigger: trigger,
          start: "top center",
          end: "bottom 90%",
          onEnter: () => {
            const loadTextElements =
              document.querySelectorAll("[data-load-text]");
            gsap.set(loadTextElements, { autoAlpha: 1 });
            initCodeIn();
            initRandomWords();
          },
          // markers: true,
        });
      });
    }
  }

  initHeroSettings();

  setTimeout(() => {
    window.app.loader.on("loader-finished", () => {
      initVideoLoad();
      initHeroScroll();
      gsap.to("[data-load-number]", {
        delay: 0.5,
        duration: 1,
        scrambleText: {
          text: "ASSETS LOADED",
          chars: "░▒▓▀▄",
          revealDelay: 0.01,
          delimiter: "",
          speed: 1,
        },
        onComplete: () => {
          initSlash();
          gsap.to("[data-load-number]", {
            delay: 2,
            scrambleText: {
              text: "SCROLL ENABLED",
              chars: "░▒▓▀▄",
              revealDelay: 0.01,
              delimiter: "",
              speed: 1,
            },
            onComplete: () => {
              if (window.isMobile) {
                destroyRandomWords();
                destroyCodeIn();
              }
              gsap.to("[data-load-number]", {
                autoAlpha: 0,
                duration: 0.1,
                yoyo: true,
                repeat: 10,
                onComplete: () => {
                  gsap.to("[data-load-number]", {
                    autoAlpha: 1,
                    duration: 0.2,
                    onComplete: () => {
                      gsap.to("[data-load-number]", {
                        delay: 2,
                        scrambleText: {
                          text: " ",
                          chars: "░▒▓▀▄",
                          revealDelay: 0.01,
                          delimiter: "",
                          speed: 1,
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
    });
  }, 1500);

  // setTimeout(initHeroScroll, 10000); // initHeroScroll();
  // initSlash();
}

// ---------------------------------------- Empower section
function initEmpowerTabs() {
  const tabWrap = document.querySelector("[data-emp-tabs-w]");

  // Ensure tabWrap exists
  if (!tabWrap) {
    console.error("Tab wrapper not found");
    return;
  }

  const tabGroupTriggers = tabWrap.querySelectorAll(".emp__tab--btn");
  const tabTriggers = tabWrap.querySelectorAll("[data-tab-trigger]");
  const tabTargets = tabWrap.querySelectorAll("[data-tab-target]");
  const tabSubMenuBtns = tabWrap.querySelectorAll(".epm__sub-btn--w");

  ScrollTrigger.create({
    trigger: tabWrap,
    start: "top center",
    end: "bottom 90%",
    onEnter: () => {
      window.app.transitionBarSceneIn();
    },
    once: true,
  });

  // Function to handle tab trigger clicks
  tabTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const targetId = trigger.getAttribute("data-tab-trigger");
      tabTargets.forEach((target) => {
        if (target.getAttribute("data-tab-target") === targetId) {
          target.click();
          setTimeout(refreshScrollTriggers, 500);
          setTimeout(lenisResize, 1000);
        }
      });
    });
  });

  // Function to handle tab group trigger clicks
  tabGroupTriggers.forEach((groupTrigger) => {
    groupTrigger.addEventListener("click", () => {
      // Adding 'is--active' to clicked group trigger
      groupTrigger.classList.add("is--active");

      // Removing 'is--active' from other group triggers
      tabGroupTriggers.forEach((otherTrigger) => {
        if (otherTrigger !== groupTrigger) {
          otherTrigger.classList.remove("is--active");
        }
      });

      // Get the parent tabColWrap
      const parentTabColWrap = groupTrigger.parentElement;

      // Adding 'is--active' to the parent of clicked trigger
      if (parentTabColWrap) {
        parentTabColWrap.classList.add("is--active");
      }

      // Removing 'is--active' from other tabColWrap elements
      tabGroupTriggers.forEach((otherTrigger) => {
        const otherParentTabColWrap = otherTrigger.parentElement;
        if (
          otherParentTabColWrap &&
          otherParentTabColWrap !== parentTabColWrap
        ) {
          otherParentTabColWrap.classList.remove("is--active");
        }
      });
    });
  });

  // Function to handle sub menu button clicks
  tabSubMenuBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      // Adding 'is--active' to clicked sub menu button
      btn.classList.add("is--active");

      // Removing 'is--active' from other sub menu buttons
      tabSubMenuBtns.forEach((otherBtn) => {
        if (otherBtn !== btn) {
          otherBtn.classList.remove("is--active");
        }
      });
    });
  });

  function initEmpTabSwitcher() {
    const tabContent = Array.from(document.querySelectorAll(".emp__tab--w"));
    const delays = [0.2, 0.3, 0.5];

    tabContent.forEach((tab) => {
      if (!tab.classList.contains("w--tab-active")) {
        const elems = tab.querySelectorAll(
          ".emp__sub-img--w, .emp__side-anim--w, .emp__para--w",
        );
        elems.forEach((elem) => {
          const transformProperty = Math.random() < 0.5 ? "x" : "y";
          const transformValue = Math.random() < 0.5 ? "-4rem" : "4rem";
          gsap.set(elem, { autoAlpha: 0, [transformProperty]: transformValue });
        });
      }
    });

    const observerCallback = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          tabContent.forEach((tab) => {
            const isActive = tab.classList.contains("w--tab-active");
            const elems = tab.querySelectorAll(
              ".emp__sub-img--w, .emp__side-anim--w, .emp__para--w",
            );
            if (isActive) {
              let repeat = Math.floor(Math.random() * 3) + 2;
              elems.forEach((elem) => {
                let delay = delays[Math.floor(Math.random() * delays.length)];
                gsap.to(elem, {
                  delay: delay,
                  autoAlpha: 1,
                  duration: 0.1,
                  repeat: repeat,
                  ease: "none",
                  onComplete: () => {
                    elem.style.removeProperty("transform");
                  },
                });
              });
            } else {
              const transformProperty = Math.random() < 0.5 ? "x" : "y";
              const transformValue = Math.random() < 0.5 ? "-4rem" : "4rem";
              gsap.set(elems, {
                autoAlpha: 0,
                [transformProperty]: transformValue,
              });
            }
          });
        }
      }
    };

    const observer = new MutationObserver(observerCallback);
    tabContent.forEach((tab) => observer.observe(tab, { attributes: true }));
  }

  function initEmpGlSwitcher() {
    const glHide = document.querySelector("[data-bar-gl='hide']");
    const glShow = document.querySelector("[data-bar-gl='show']");

    if (!glHide || !glShow) {
      return;
    } else {
      glHide.addEventListener("click", () => {
        window.app.transitionBarSceneOut();
      });

      glShow.addEventListener("click", () => {
        window.app.transitionBarSceneIn();
      });
    }
  }

  initEmpTabSwitcher();
  initEmpGlSwitcher();
}

// ---------------------------------------- Institutional section

function initHorizontalScroll() {
  // Function to set the heights of sections based on the track width
  function setTrackHeights() {
    document.querySelectorAll("[data-ip-section]").forEach((section) => {
      const track = section.querySelector("[data-ip-track]");
      if (track) {
        const trackWidth = track.offsetWidth; // Get the width of the track
        section.style.height = `${trackWidth}px`; // Set the height of the section
      }
    });
  }

  // Set initial track heights and update on window resize
  setTrackHeights();
  window.addEventListener("resize", setTrackHeights);

  // Initialize GSAP timeline for horizontal scrolling
  let tlMain = gsap
    .timeline({
      scrollTrigger: {
        trigger: "[data-ip-section]",
        start: "top top",
        end: "98% bottom",
        scrub: 1,
      },
    })
    .to("[data-ip-track]", {
      xPercent: -100,
      ease: "none",
    });

  let duration = 0.1;
  const delays = [0.5, 0.8, 1];

  const elems = document.querySelectorAll("[data-gp-parent] .ip__logo--item");
  gsap.set(elems, { autoAlpha: 0, y: "4rem" });

  // logo anims when [data-gp-parent] enters the viewport
  ScrollTrigger.create({
    trigger: "[data-gp-parent]",
    containerAnimation: tlMain,
    start: "left 85%",
    onEnter: () => {
      elems.forEach((elem) => {
        const overlay = elem.querySelector(".ip__logo--ol");
        let repeat = Math.floor(Math.random() * 3) + 2;
        let delay = delays[Math.floor(Math.random() * delays.length)];

        gsap.to(elem, {
          delay: delay,
          autoAlpha: 1,
          duration: duration,
          repeat: repeat,
          ease: "none",
          onComplete: () => {
            elem.style.removeProperty("transform");
          },
        });
        gsap.to(overlay, {
          delay: delay + 0.1,
          autoAlpha: 0,
          duration: duration,
          repeat: repeat,
          ease: "none",
          onComplete: () => {
            elem.style.removeProperty("transform");
          },
        });
      });
    },
  });

  // text anims when [data-gp-parent] enters the viewport

  const text = document.querySelector("[data-gp-flash]");
  const words = text.querySelectorAll(".word");
  gsap.set(words, { autoAlpha: 0 });

  ScrollTrigger.create({
    trigger: "[data-gp-flash]",
    containerAnimation: tlMain,
    start: "left 85%",
    once: true,
    onEnter: () => {
      words.forEach((word) => {
        const transformProperty = Math.random() < 0.5 ? "x" : "y";
        const transformValue = Math.random() < 0.5 ? "-4rem" : "4rem";
        let repeat = Math.floor(Math.random() * 3) + 2;

        // Set initial properties for flashing
        gsap.set(word, { autoAlpha: 0, [transformProperty]: transformValue });

        // Create ScrollTrigger for each word
        ScrollTrigger.create({
          trigger: word,
          start: "top 80%",
          onEnter: () => {
            gsap.to(word, {
              autoAlpha: 1,
              duration: 0.1,
              repeat: repeat,
              ease: "none",
              onComplete: () => {
                word.style.removeProperty("transform");
              },
            });
          },
        });
      });
    },
  });
}

export function initHome() {
  if (!window.isTabletOrBelow) {
    initHorizontalScroll();
  }
  // initVideoLoad();
  // window.addEventListener("load", initVideoLoad);
  initIntro();
  initEmpowerTabs();
  initAnims();
  initSwipers();
}
