/*
New 3d Model of logo added for large rotating token, texture for model removed - newToken.glb
Added 2 new SVG's (old one removed) - centered-scaled-svg.svg and newLogoSimple.svg
Added Environment maps, 6 files added to enhance the token look without the texture - px.webp, nx.webp, py.webp, ny.webp, pz.webp, nz.webp.
New Space scene added + Space scene depth map - spaceScene.webp, spaceSceneDepth.webp
*/

export function initWebGL() {
  window.appStart = function (App) {
    const appliction = new App(document.querySelector("canvas.webgl"), [
      {
        name: "svg7",
        type: "svg",
        path: "https://assets.itsoffbrand.io/breeder2024/svg/centered-scaled-svg.svg",
      },
      {
        name: "logoSimple",
        type: "svg",
        path: "https://assets.itsoffbrand.io/breeder2024/svg/newLogoSimple.svg",
      },
      {
        name: "font",
        type: "font",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/fonts/Archivo_Expanded-Bold.ttf",
      },
      {
        name: "token",
        type: "gltfModel",
        path: "https://assets.itsoffbrand.io/breeder2024/models/newToken.glb",
      },
      //   {
      //     name: "tokenTexture",
      //     type: "texture",
      //     path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/tokenTexture.webp",
      //   },
      {
        name: "barDepthMap",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/barSceneDarkDepth2.webp",
      },
      {
        name: "barTexture",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/barSceneDarkTexture.webp",
      },
      {
        name: "spaceTexture",
        type: "texture",
        path: "https://assets.itsoffbrand.io/breeder2024/textures/spaceNew/spaceScene.webp",
      },
      {
        name: "spaceDepthMap",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f515a576ad61485c66e5d2_spaceSceneDepth.webp",
      },
      {
        name: "spaceSceneAlpha",
        type: "texture",
        path: "https://assets.itsoffbrand.io/breeder2024/textures/spaceNew/spaceSceneAlpha.webp",
      },
      // left lift door
      {
        name: "leftLiftDoor",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f297de9ca3658c4a5f2d90_sov-elev-left-no-fill-v3.webp",
      },
      // right lift door
      {
        name: "rightLiftDoor",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f297de0b2b56ea1eadc071_sov-elev-right-no-fill-v3.webp",
      },
      // end doors
      {
        name: "text",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f5157b07f0eef01a1365e9_led-marquee-fix.webp",
      },
      {
        name: "backdrop1",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f3c03f54d2fadf5eada06a_sovrun-hero-1.webp",
      },
      {
        name: "backdrop2",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f3c03f7e9c6c041048de55_sovrun-hero-2.webp",
      },
      {
        name: "backdrop3",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f3c03f6b31e9f8d238e9eb_sovrun-hero-3.webp",
      },
      {
        name: "backdrop4",
        type: "texture",
        path: "https://cdn.prod.website-files.com/6617bb5f148294d058310076/66f3c03f17c35eb32024fd1d_sovrun-hero-4.webp",
      },
      {
        name: "backdrop1Depth",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/backdrop1Depth.webp",
      },
      {
        name: "backdrop2Depth",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/backdrop2Depth.webp",
      },
      {
        name: "backdrop3Depth",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/backdrop3Depth.webp",
      },
      {
        name: "backdrop4Depth",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/backdrop4Depth.webp",
      },
      //   {
      //     name: "boltMask",
      //     type: "texture",
      //     path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/boltMask10.webp",
      //   },
      //   {
      //     name: "boltMaskOutline",
      //     type: "texture",
      //     path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/boltMask15.webp",
      //   },
      // new
      {
        name: "shapeMaskOne",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/squareMaskOneShape.webp",
      },
      //new
      {
        name: "shapeMaskTwo",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/squareMaskTwoShape.webp",
      },
      //new
      {
        name: "squareMaskOne",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/squareMaskOneSquare.webp",
      },
      //new
      {
        name: "squareMaskTwo",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/squareMaskTwoSquare.webp",
      },
      {
        name: "barSceneAlpha",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/barSceneAlpha.webp",
      },
      {
        name: "barA",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/breederBarAlpha.webp",
      },
      {
        name: "spaceA",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/breederLiftAlpha.webp",
      },
      // view
      {
        name: "backView",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/view/back.webp",
      },
      {
        name: "midView",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/view/mid.webp",
      },
      {
        name: "frontView",
        type: "texture",
        path: "https://breederdao-site-prod.s3.ap-southeast-1.amazonaws.com/textures/view/front.webp",
      },
      {
        name: "envMap",
        type: "cubeTexture",
        path: [
          "https://assets.itsoffbrand.io/breeder2024/textures/environmentMap/px.webp",
          "https://assets.itsoffbrand.io/breeder2024/textures/environmentMap/nx.webp",
          "https://assets.itsoffbrand.io/breeder2024/textures/environmentMap/py.webp",
          "https://assets.itsoffbrand.io/breeder2024/textures/environmentMap/ny.webp",
          "https://assets.itsoffbrand.io/breeder2024/textures/environmentMap/pz.webp",
          "https://assets.itsoffbrand.io/breeder2024/textures/environmentMap/nz.webp",
        ],
      },
    ]);

    appliction.resources.on("ready", () => {
      // ready
      const app = document.getElementById("app");
      app.style.cssText = "display: inherit";
      app.style.margin = "0";
      app.style.padding = "0";
    });
  };
  window.SScroll.start();
}
