import gsap from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

gsap.registerPlugin(ScrambleTextPlugin);

export function initCursor() {
  const cursWrap = document.querySelector("[data-cursor-wrap]");
  const cursTrans = document.querySelector('[data-curs="trans"]');
  const cursRings = document.querySelectorAll('[data-curs="rings"]');
  const cursOuterRing = document.querySelector('[data-curs="outer"]');
  const cursInnerRing = document.querySelector('[data-curs="inner"]');
  const cursText = document.querySelector('[data-curs="text"]');
  const circleElement = document.querySelector('[data-curs="scale"]');
  const cursTextWrap = document.querySelector('[data-curs="text-wrap"]');

  let isHovered = false;
  const mouse = { x: 0, y: 0, previousX: 0, previousY: 0 };
  let currentScale = 0;
  let currentAngle = 0;

  // Initialize states
  function initStates() {
    gsap.set(cursWrap, { autoAlpha: 0 });
  }

  // Update cursor position
  function updateCursorPosition(e) {
    const xOffset = cursTrans.offsetWidth / 2;
    const yOffset = cursTrans.offsetHeight / 2;

    gsap.to(cursTrans, {
      x: e.clientX - xOffset,
      y: e.clientY - yOffset,
      duration: 0.2,
      ease: "power3",
      overwrite: "auto",
    });

    mouse.x = e.clientX;
    mouse.y = e.clientY;
  }

  // Mouse movement handling
  function mouseMove() {
    document.addEventListener("mousemove", (e) => {
      updateCursorPosition(e);
    });

    document.addEventListener("mouseover", () => {
      gsap.to(cursWrap, { autoAlpha: 1, duration: 0.3 });
    });

    document.addEventListener("mouseleave", () => {
      gsap.to(cursWrap, { autoAlpha: 0, duration: 0.3 });
    });
  }

  // Dynamic cursor effects based on mouse movement
  function tick() {
    const speed = 0.1;
    const dx = mouse.x - mouse.previousX;
    const dy = mouse.y - mouse.previousY;

    // Squeeze effect
    const velocity = Math.sqrt(dx * dx + dy * dy);
    const targetScale = 1 + (velocity > 1 ? velocity * 0.01 : 0);
    currentScale += (targetScale - currentScale) * speed;

    // Rotate effect
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);
    currentAngle += (angle - currentAngle) * speed;

    circleElement.style.transform = `scale(${currentScale}) rotate(${currentAngle}deg)`;

    mouse.previousX = mouse.x;
    mouse.previousY = mouse.y;

    requestAnimationFrame(tick);
  }

  function animateCursorRings() {
    // Animate cursInnerRing
    gsap.to(cursInnerRing.querySelector("path"), {
      strokeDashoffset: 30,
      duration: 3,
      repeat: -1,
      yoyo: true,
    });

    gsap.to(cursInnerRing, {
      rotation: 180,
      duration: 6,
      repeat: -1,
      yoyo: true,
      transformOrigin: "50% 50%",
    });

    // Animate cursOuterRing
    gsap.to(cursOuterRing.querySelector("path"), {
      strokeDashoffset: 70,
      duration: 3,
      repeat: -1,
      yoyo: true,
    });

    gsap.to(cursOuterRing, {
      rotation: -180,
      duration: 10,
      repeat: -1,
      yoyo: true,
      transformOrigin: "50% 50%",
    });
  }

  function hoversAndScramble() {
    const hoverElem = document.querySelectorAll("[data-curs-hover]");
    let isHovered = false; // To track if hoverElem is hovered
    let duration = 0.4;

    hoverElem.forEach((elem) => {
      elem.addEventListener("mouseenter", () => {
        isHovered = true; // Set hover state to true
        const hoverText = elem.getAttribute("data-curs-hover");
        gsap.to(cursText, {
          duration: 1,
          scrambleText: {
            text: hoverText,
            chars: "░▒▓▀▄",
            revealDelay: 0.01,
            delimiter: "",
            speed: 1,
          },
        });
        gsap.to(cursRings, { scale: 1.5, duration: 0.2 }); // Scale up
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeWidth: 2,
          duration: 0.5,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeWidth: 2,
          duration: 0.5,
        });

        gsap.killTweensOf([
          cursInnerRing.querySelector("path"),
          cursOuterRing.querySelector("path"),
          cursInnerRing,
          cursOuterRing,
        ]);

        // Animate strokeDashoffset to 0 for both rings
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeDashoffset: 0,
          duration: duration,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeDashoffset: 0,
          duration: duration,
        });

        // Animate rotation of the cursOuterRing back to 0
        gsap.to(cursOuterRing, {
          rotation: 0,
          duration: duration,
          transformOrigin: "50% 50%",
        });
        gsap.to(cursInnerRing, {
          rotation: 0,
          duration: duration,
          transformOrigin: "50% 50%",
        });
      });

      elem.addEventListener("mouseleave", () => {
        isHovered = false; // Reset hover state
        gsap.to(cursText, {
          duration: 1,
          scrambleText: {
            text: "+",
            chars: "░▒▓▀▄",
            revealDelay: 0.01,
            delimiter: "",
            speed: 1,
          },
        });
        gsap.to(cursRings, { scale: 1, duration: 0.2 }); // Scale back
        gsap.to(cursOuterRing, { strokeWidth: 4, duration: 0.5 });
        gsap.to(cursInnerRing, { strokeWidth: 4, duration: 0.5 });

        animateCursorRings();
      });
    });
  }

  function scaleCursorOnMouseDown() {
    let duration = 0.4;
    document.addEventListener("mousedown", () => {
      if (!isHovered) {
        gsap.killTweensOf([
          cursInnerRing.querySelector("path"),
          cursOuterRing.querySelector("path"),
          cursInnerRing,
          cursOuterRing,
        ]);

        // Animate strokeDashoffset to 0 for both rings
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeDashoffset: 0,
          duration: duration,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeDashoffset: 0,
          duration: duration,
        });

        // Animate rotation of the cursOuterRing back to 0
        gsap.to(cursOuterRing, {
          rotation: 0,
          duration: duration,
          transformOrigin: "50% 50%",
        });
        gsap.to(cursInnerRing, {
          rotation: 0,
          duration: duration,
          transformOrigin: "50% 50%",
        });

        // Only scale if hoverElem is not hovered
        gsap.to(cursRings, { scale: 0.6, duration: 0.5 });
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeWidth: 6,
          duration: duration,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeWidth: 6,
          duration: duration,
        });
      }
    });

    document.addEventListener("mouseup", () => {
      if (!isHovered) {
        gsap.to(cursRings, { scale: 1, duration: 0.5 });
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeWidth: 4,
          duration: duration,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeWidth: 4,
          duration: duration,
        });
      }

      animateCursorRings();
    });

    // hover links etc
    const hoverElements = document.querySelectorAll(
      'a, button, [role="button"]',
    );

    hoverElements.forEach((element) => {
      element.addEventListener("mouseenter", () => {
        isHovered = true;
        gsap.killTweensOf([
          cursInnerRing.querySelector("path"),
          cursOuterRing.querySelector("path"),
          cursInnerRing,
          cursOuterRing,
        ]);

        // Animate strokeDashoffset to 0 for both rings
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeDashoffset: 0,
          duration: duration,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeDashoffset: 0,
          duration: duration,
        });

        // Animate rotation of the cursOuterRing back to 0
        gsap.to(cursOuterRing, {
          rotation: 0,
          duration: duration,
          transformOrigin: "50% 50%",
        });
        gsap.to(cursInnerRing, {
          rotation: 0,
          duration: duration,
          transformOrigin: "50% 50%",
        });

        gsap.to(cursRings, { scale: 0.6, duration: 0.5 });
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeWidth: 6,
          duration: 0.5,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeWidth: 6,
          duration: 0.5,
        });
      });

      element.addEventListener("mouseleave", () => {
        isHovered = false;
        gsap.to(cursRings, { scale: 1, duration: 0.5 });
        gsap.to(cursInnerRing.querySelector("path"), {
          strokeWidth: 4,
          duration: 0.5,
        });
        gsap.to(cursOuterRing.querySelector("path"), {
          strokeWidth: 4,
          duration: 0.5,
        });
        animateCursorRings();
      });
    });
  }

  // Initialize cursor functionality
  initStates();
  mouseMove();
  animateCursorRings();
  hoversAndScramble();
  scaleCursorOnMouseDown();

  // Start the tick function
  tick();
}
