import gsap from "gsap";
import Swiper from "swiper";
import { FreeMode, Pagination, Parallax } from "swiper/modules";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// ------------------------------------------ asset slider
function initAssetSlider() {
  const swiperWrap = document.querySelector("[data-asset-section]");
  if (!swiperWrap) return;
  const swiperInstance = swiperWrap.querySelector(".swiper");
  if (!swiperInstance) return;

  let slidesPerView = 2.5;
  if (window.isTabletOrBelow) {
    slidesPerView = "auto";
  }

  // pagination
  const paginationWrap = swiperWrap.querySelector("[data-pagi-w]");
  const sideLinkWrap = swiperWrap.querySelector("[data-slider-slide-links]");
  // side links
  const sideLinks = sideLinkWrap.querySelectorAll("[data-slider-slide-link]");

  const swiper = new Swiper(swiperInstance, {
    modules: [Pagination],
    slidesPerView: slidesPerView,
    spaceBetween: 0,
    loop: true,
    centeredSlides: false,
    slideActiveClass: "is--active",
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    pagination: {
      el: paginationWrap,
      type: "bullets",
      clickable: true,
      bulletClass: "asset__pagination",
      bulletActiveClass: "is--active",
    },
  });

  // synchronise active class
  swiper.on("slideChange", () => {
    const activeIndex = swiper.realIndex;
    sideLinks.forEach((link, index) => {
      link.classList.toggle("is--active", index === activeIndex);
    });
  });

  // synchronise side links and pagination
  sideLinks.forEach((link, index) => {
    link.setAttribute("data-slide-to", index);
    link.addEventListener("click", () => {
      const slideIndex = parseInt(link.getAttribute("data-slide-to"), 10);
      swiper.slideToLoop(slideIndex);
    });
  });
}

// ------------------------------------------ cards slider
function initCardsSlider() {
  const swiperWrap = document.querySelector("[data-cards-section]");
  if (!swiperWrap) return;
  const swiperInstance = swiperWrap.querySelector(".swiper");
  if (!swiperInstance) return;

  // Assuming the slides are direct children of the swiperInstance
  const totalSlides = swiperInstance.querySelectorAll(".swiper-slide").length;
  const middleSlideIndex = Math.floor(totalSlides / 2);

  const swiper = new Swiper(swiperInstance, {
    slidesPerView: "auto",
    spaceBetween: 16,
    loop: false,
    centeredSlides: true,
    slideActiveClass: "is--active",
    // simulateTouch: true,
    touchStartPreventDefault: false,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    initialSlide: middleSlideIndex, // Start with the middle slide
    on: {
      init: function () {
        scaleSlides(this);
      },
      slideChange: function () {
        scaleSlides(this);
      },
    },
  });

  function initCardRotateHover() {
    const cards = document.querySelectorAll("[data-vision-slide]");
    const enterEase = "power3.out";
    const leaveEase = "power2.out";
    const enterDuration = 0.6;
    const leaveDuration = 0.6;

    cards.forEach((card) => {
      const trans = card.querySelector(".vc__trans--w");

      function handleMouseEnter() {
        if (card.classList.contains("is--active")) {
          // Determine rotation: either 180 or 540 degrees on the Y-axis
          const rotationY = Math.random() > 0.5 ? 180 : 540;
          gsap.killTweensOf(trans); // Ensure no overlapping tweens
          gsap.to(trans, {
            rotationY: `+=${rotationY}`,
            ease: enterEase,
            duration: enterDuration,
          });
          card.dataset.facing = rotationY === 180 ? "back" : "front"; // Track the card's facing
        }
      }

      function handleMouseLeave() {
        if (card.classList.contains("is--active")) {
          gsap.killTweensOf(trans); // Ensure no overlapping tweens
          // Always rotate back to a multiple of 360 degrees for the front face
          const targetRotationY = card.dataset.facing === "back" ? 360 : 0;
          gsap.to(trans, {
            rotationY: targetRotationY,
            ease: leaveEase,
            duration: leaveDuration,
          });
        }
      }

      card.addEventListener("mouseenter", handleMouseEnter);
      card.addEventListener("mouseleave", handleMouseLeave);

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.attributeName === "class" &&
            !card.classList.contains("is--active")
          ) {
            gsap.killTweensOf(trans); // Also kill tweens when "is--active" class is removed
            gsap.to(trans, {
              rotationY: 0,
              ease: leaveEase,
              duration: leaveDuration,
            });
          }
        });
      });

      observer.observe(card, { attributes: true });
    });

    swiper.on("slideChange", function () {
      const activeSlide = swiper.slides[swiper.activeIndex];
      const hexValue = activeSlide.getAttribute("data-vision-slide");

      if (hexValue) {
        window.app.slideGradient(hexValue);
      } else {
        console.warn("nae hex");
      }
    });
  }

  function initSideScroll() {
    ScrollTrigger.create({
      trigger: swiperWrap,
      start: "top bottom",
      onEnter: () => setTimeout(() => swiper.slideTo(0), 1500),
    });
  }

  initSideScroll();
  initCardRotateHover();
}

function scaleSlides(swiper) {
  // Reset all slides to a base scale first
  swiper.slides.forEach((slide) => {
    gsap.to(slide, { scale: 0.85, duration: 0.3 });
  });

  const activeIndex = swiper.realIndex;

  // Ensure the active slide is scaled to 1
  gsap.to(swiper.slides[activeIndex], {
    scale: 1,
    duration: 0.3,
    autoAlpha: 1,
  });

  // Scale slides to the left of the active slide
  if (activeIndex > 0)
    gsap.to(swiper.slides[activeIndex - 1], {
      scale: 0.9,
      duration: 0.3,
      autoAlpha: 0.8,
    });
  if (activeIndex > 1)
    gsap.to(swiper.slides[activeIndex - 2], {
      scale: 0.8,
      duration: 0.3,
      autoAlpha: 0.6,
    });
  if (activeIndex > 2)
    gsap.to(swiper.slides[activeIndex - 3], {
      scale: 0.7,
      duration: 0.3,
      autoAlpha: 0.4,
    });
  if (activeIndex > 3)
    gsap.to(swiper.slides[activeIndex - 4], {
      scale: 0.6,
      duration: 0.3,
      autoAlpha: 0.2,
    });

  // Scale slides to the right of the active slide
  if (activeIndex < swiper.slides.length - 1)
    gsap.to(swiper.slides[activeIndex + 1], {
      scale: 0.9,
      duration: 0.3,
      autoAlpha: 0.8,
    });
  if (activeIndex < swiper.slides.length - 2)
    gsap.to(swiper.slides[activeIndex + 2], {
      scale: 0.8,
      duration: 0.3,
      autoAlpha: 0.6,
    });
  if (activeIndex < swiper.slides.length - 3)
    gsap.to(swiper.slides[activeIndex + 3], {
      scale: 0.7,
      duration: 0.3,
      autoAlpha: 0.4,
    });
  if (activeIndex < swiper.slides.length - 4)
    gsap.to(swiper.slides[activeIndex + 4], {
      scale: 0.6,
      duration: 0.3,
      autoAlpha: 0.2,
    });
}

// ------------------------------------------ advisor slider
function initAdvSlider() {
  const swiperWrap = document.querySelector("[data-adv-section]");
  if (!swiperWrap) return;
  const swiperInstance = swiperWrap.querySelector(".swiper");
  if (!swiperInstance) return;

  // pagination
  const paginationWrap = swiperWrap.querySelector("[data-pagi-w]");
  // side links
  const sideLinkWrap = swiperWrap.querySelector("[data-slider-slide-links]");
  const sideLinks = sideLinkWrap.querySelectorAll("[data-slider-slide-link]");

  let spaceBetween = 0;

  if (window.isTabletOrBelow) {
    spaceBetween = 16;
  }

  const swiper = new Swiper(swiperInstance, {
    modules: [Pagination],
    slidesPerView: "auto",
    spaceBetween: spaceBetween,
    loop: true,
    centeredSlides: false,
    slideActiveClass: "is--active",
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    pagination: {
      el: paginationWrap,
      type: "bullets",
      clickable: true,
      bulletClass: "adv__pagi--dot",
      bulletActiveClass: "is--active",
    },
  });

  // synchronise active class
  swiper.on("slideChange", () => {
    const activeIndex = swiper.realIndex;
    sideLinks.forEach((link, index) => {
      link.classList.toggle("is--active", index === activeIndex);
    });
  });

  // synchronise side links and pagination
  sideLinks.forEach((link, index) => {
    link.setAttribute("data-slide-to", index);
    link.addEventListener("click", () => {
      const slideIndex = parseInt(link.getAttribute("data-slide-to"), 10);
      swiper.slideToLoop(slideIndex);
    });
  });
}

// ------------------------------------------ blog slider
function initBlogSlider() {
  const swiperWrap = document.querySelector("[data-blog-section]");
  if (!swiperWrap) return;
  const swiperInstance = swiperWrap.querySelector(".swiper");
  if (!swiperInstance) return;

  let centeredSlideValue = true;
  if (window.isTabletOrBelow) {
    centeredSlideValue = false;
  }

  const swiper = new Swiper(swiperInstance, {
    modules: [Parallax],
    slidesPerView: "auto",
    spaceBetween: 0,
    loop: true,
    speed: 600,
    centeredSlides: centeredSlideValue,
    parallax: true,
    slideActiveClass: "is--active",
    // loopAdditionalSlides: 8,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    // freeMode: {
    //   enabled: true,
    //   sticky: true,
    // },
  });
}

// ------------------------------------------ team slider
function initTeamSlider() {
  const swiperWrap = document.querySelector("[data-team-section]");
  if (!swiperWrap) return;
  const swiperInstance = swiperWrap.querySelector(".swiper");
  if (!swiperInstance) return;

  const swiper = new Swiper(swiperInstance, {
    slidesPerView: "auto",
    spaceBetween: 32,
    loop: false,
    speed: 300,
    centeredSlides: false,
    slideActiveClass: "is--active",
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });
}

export function initSwipers() {
  initAssetSlider();
  initAdvSlider();
  initCardsSlider();
  initBlogSlider();
  initTeamSlider();
  if (!window.isTabletOrBelow) {
  }
}
